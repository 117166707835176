<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <!-- tooltip -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="toggleProgress">mdi-chart-line</v-icon>
            </template>
            <span>Indicateurs de stockage</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-card class="mx-auto" style="width: 20rem;">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="mb-5">Indicateurs</v-list-item-title>
            <v-list-item-subtitle
              class="mb-2 d-flex align-center justify-space-between"
              >Espace de stockage
              <span :style="{ color: progressColor }"
                >{{ total }} Go / {{ possible }} Go</span
              >
            </v-list-item-subtitle>
            <div class="d-flex align-center" style="gap: 1rem;">
              <v-progress-linear
                :value="progressValue"
                height="5"
                :color="progressColor"
                rounded
              >
              </v-progress-linear>
              <div style="font-size: 0.8rem;" :style="{ color: progressColor }">
                {{ progressValue }}%
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Api from '@/Services/Api';
import AppService from '@/Services/AppService';
import axios from 'axios';

export default {
  name: 'StorageIndicator',
  data() {
    return {
      progressValue: 0,
      total: 0,
      possible: 0,
      restant: 0,
      totalInGo: 0,
      possibleInGo: 0,
      restantInGo: 0,
      totalInTo: 0,
      possibleInTo: 0,
      restantInTo: 0,
      progressColor: 'green',
      storageValue: 0,
      hostname: '',
    };
  },
  methods: {
    toggleProgress() {
      this.loadStorageData();
    },
    async loadStorageData() {
      try {
        const response = await axios.get(
          `https://${this.hostname}/recup_info_stockage.php5`
        );
        const data = response.data;

        const { CONSO_TOTAL, CONSO_POSSIBLE, CONSO_RESTANT } = data;
        this.total = CONSO_TOTAL;
        this.possible = CONSO_POSSIBLE;
        this.restant = CONSO_RESTANT;

        // Calcule de la valeur de la progress bar en %
        this.progressValue = (CONSO_TOTAL / CONSO_POSSIBLE) * 100;
        // arrondir a 2 chiffres après la virgule
        this.progressValue = Math.round(this.progressValue * 100) / 100;

        // Changement de couleur de la progress bar en fonction de la valeur
        if (this.progressValue >= 90) {
          this.progressColor = 'red';
          // Envoi d'une notification par email si la valeur est supérieure à 90%
          this.sendEmailNotification();
        } else if (this.progressValue >= 50) {
          this.progressColor = 'orange';
        } else {
          // Si la valeur est inférieure à 50%
          this.progressColor = 'green';
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de l'API",
          error
        );
      }
    },
    async sendEmailNotification() {
      await Api().post('/interface/mail');
    },
  },
  async mounted() {
    if (this.hostname != undefined)
    this.hostname = window.location.hostname;
    if (this.hostname == 'localhost') {
      this.hostname = await AppService.getDns();
    }
  }
};
</script>
